import React, { useEffect, useState } from 'react';
import useTypewriter from "react-typewriter-hook";

const PageMovingText = () => {
    const [typingText, setTypingText] = useState("");
    const text = useTypewriter(typingText);

    useEffect(() => {
        setTimeout(() => {
            setTypingText("Think,");
        }, 0);
        setTimeout(() => {
            setTypingText("Learn,");
        }, 4000);
        setTimeout(() => {
            setTypingText("Improve,");
        }, 10000);
        setTimeout(() => {
            setTypingText("Strive for excellence!");
        }, 16000);
    }, []);

    return (
        <div
            className="text-body-color"
            id="movingtext"
        >
            <p className="cursor">{text}</p>
        </div>
    );
}


export default PageMovingText;
