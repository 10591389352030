import PropTypes from 'prop-types';
import React, { Component } from 'react';

class PersonalTabbedPane extends Component {

    constructor (props) {
        super(props);
        this.state = {...props};
    }

    static getDerivedStateFromProps (nextProps, prevState) {
        return {
            urls: nextProps.urls,
            index: nextProps.index,
        };
    }

    handleClick (value) {
        if (value === 0) {
            this.setState({index: 0});
        } else if (value === 1) {
            this.setState({index: 1});
        } else if (value === 2) {
            this.setState({index: 2});
        } else {
            this.setState({index: 3});
        }
    }

    addMoreIcons () {
        const { index } = this.state;

        this.setState({index : index + 1});
    }

    render () {
        // array 0 - 3
        const tempArr = [];
        for (let i = 0; i < this.state.urls.length; i++) {
            tempArr.push(<span className="tech-icon" key={`tabbed-key${i}`}><img src={this.state.urls[i]} alt="" /></span>);
        }
        let liClasses = ["btn btn-default", "btn btn-default", "btn btn-default", "btn btn-default"];
        let paneClasses = ["tab-pane", "tab-pane", "tab-pane", "tab-pane"];
        liClasses[this.state.index] += " active";
        paneClasses[this.state.index] += " active";
        return (
            <div className="span12">
                <div>
                    <span><div className="">My Skill Set</div></span>
                    {tempArr}
                </div>
            </div>
        );
    }
}

PersonalTabbedPane.defaultProps = {
    urls: ["img/techicons/centos.png", "img/techicons/debian.png", "img/techicons/docker.png", "img/techicons/ubuntu.png", "img/techicons/react.png", "img/techicons/laravel.png"],
    index: 0,
};

PersonalTabbedPane.propTypes = {
    urls: PropTypes.array,
    index: PropTypes.number,
};
export default PersonalTabbedPane;
