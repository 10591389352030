import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

const TopNavbar = (href = "#") => {
    return (
        <nav className="navbar navbar-expand-sm bg-dark navbar-dark cus-navbar">
            <NavLink
                className="navbar-brand"
                to={href}
            >
                Yue Li
            </NavLink>
        </nav>
    );
}

TopNavbar.defaultProps = {
    href: "#noop",
};

TopNavbar.propTypes = {
    href: PropTypes.string,
};

export default TopNavbar;
