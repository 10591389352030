import React, { Component } from 'react';

import TickingClock from './TickingClock';

class PersonalInfoPane extends Component {

    render () {
        return (
            <div>
                <img className="d-none d-lg-block" alt="" data-src="img/yue.webp" src="../img/fake.webp"></img>
                <TickingClock />
            </div>
        );
    }
}

export default PersonalInfoPane;
