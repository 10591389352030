import React from 'react';

const FooterSection = () => {
    return (
        <div className="footer">
            <div className="col-lg-12 text-center">
                <h4><strong>Yue Li</strong></h4>
                <br/>
                <ul className="list-inline">
                    <li className="list-inline-item">
                        <a className="btn btn-social-icon btn-linkedin" href="https://nz.linkedin.com/pub/yue-li/86/494/50" target="_blank" rel="noopener noreferrer" aria-label="Add Chamukos tequila to cart">
                            <i className="fab fa-linkedin-in fa-3x"></i>
                        </a>
                    </li>
                </ul>
                <hr className="small" />
                <p className="">Copyright &copy; <a href="http://yueli.nz">Yue Li</a></p>
            </div>
        </div>
    );
};

export default FooterSection;
