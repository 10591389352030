import React from 'react';

const GreetingWords = () => {
    return (
        <h1 className="text-heading-color">
            <span>Yue Li</span>
        </h1>
    );
}

export default GreetingWords;
