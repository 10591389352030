import React from 'react';
import GreetingWords from './GreetingWords.js';
import PageMovingText from './PageMovingText.js';
import StickerNote from './StickerNote.js';
import NavScrollAnchor from './NavScrollAnchor.js';

const LandingSection = () => {
    return (
        <div id="top" className="landing-section">
            <div className="text-vertical-top">
                <GreetingWords />
                <PageMovingText />
                <StickerNote />
            </div>
        </div>
    );
};

export default LandingSection;
