import React, { Component } from 'react';
import TopNavbar from './components/TopNavbar.js';
import LandingSection from './components/LandingSection.js';
import PersonalSection from './components/PersonalSection.js';
import FooterSection from './components/FooterSection.js';

import './App.css';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
    return (
        <BrowserRouter>
            <div className="App">
                <TopNavbar />
                <LandingSection />
                <FooterSection />
            </div>
        </BrowserRouter>
    );
}

export default App;
