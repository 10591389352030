import React, { Component } from 'react';

class TickingClock extends Component {
    constructor () {
        super();
        this.state = {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0
        };
    }
    componentDidMount () {
        let startDate = new Date();
        startDate.setFullYear(2015, 8, 30);
        startDate.setHours(9); // 0 - 23
        startDate.setMinutes(0); // 0 - 59
        startDate.setSeconds(0); // 0 - 59
        setInterval(() => {
            this.calculateTimeLapse(startDate);
        }, 1000);
    }

    calculateTimeLapse (startDate) {
        let currentDate = new Date();
        let remainingSeconds = (Date.parse(currentDate) - Date.parse(startDate)) / 1000;
        let hasDays = Math.floor(remainingSeconds / (3600 * 24));

        // hours
        remainingSeconds %= 3600 * 24;
        let hasHours = Math.floor(remainingSeconds / 3600);
        if (hasHours < 10) {
            hasHours = "0" + hasHours;
        }
        // minutes
        remainingSeconds %= 3600;
        let minutes = Math.floor(remainingSeconds / 60);
        if (minutes < 10) {
            minutes = "0" + minutes;
        }
        remainingSeconds %= 60;
        if (remainingSeconds < 10) {
            remainingSeconds = "0" + remainingSeconds;
        }
        this.setState({days: hasDays, hours: hasHours, minutes: minutes, seconds: remainingSeconds});
    }

    render () {
        return (
            <div className="clock-box">
                <font color="#2c456a">My professional practice experience is:</font>
                <div className="clock">
                    <span className="digit">{this.state.days}</span> Days
                    <span className="digit">{this.state.hours}</span> hours
                    <span className="digit">{this.state.minutes}</span> minutes
                    <span className="digit">{this.state.seconds}</span> seconds
                </div>
            </div>
        );
    }
}

export default TickingClock;
