import PropTypes from 'prop-types';
import React, { Component } from 'react';

class StickerNote extends Component {
    render () {
        return (
            <div className="recent-display-area">
                <div className="unorder-list">
                    <div className="unorder-list-item">
                        <a href={this.props.href}>
                            <h2>Web Dev</h2>
                            <p>Frontend/Backend development</p>
                        </a>
                    </div>
                    <div className="unorder-list-item">
                        <a href={this.props.href}>
                            <h2>Integration Testing</h2>
                            <p>Go live with confidence with E2E testing</p>
                        </a>
                    </div>
                    <div className="unorder-list-item">
                        <a href={this.props.href}>
                            <h2>Ice Hockey</h2>
                            <p>I play ice hockey in Auckland</p>
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}

StickerNote.defaultProps = {
    href: "#noop",
};

StickerNote.propTypes = {
    href: PropTypes.string,
}

export default StickerNote;
