import React, { Component } from 'react';

class NavScrollAnchor extends Component {
    constructor (props) {
        super(props);
        this.state = {
            shouldFlip: false
        };
        this.handleClick = this.handleClick.bind(this);
    }

    render () {
        let classNames = "btn btn-dark btn-lg ";
        if (this.state.shouldFlip) {
            classNames += "sk-rotating-plane";
        }
        return <a href={this.props.href} onClick={this.handleClick} className={classNames}>{this.props.text}</a>
    }

    handleClick () {
        let id = this.props.href.replace("#", "");
        let targetElement = document.getElementById(id);
        targetElement.classList.remove("element-hide");
        targetElement.classList.add("element-show");
        this.imagesLazyLoad();
        this.setState({shouldFlip: true});
        setTimeout(() => {
            this.setState({shouldFlip: false});
        }, 3000);
    }

    imagesLazyLoad () {
        var imgDefer = document.getElementsByTagName('img');
        for (var i = 0; i < imgDefer.length; i++) {
            if (imgDefer[i].getAttribute('data-src')) {
                imgDefer[i].setAttribute('src',imgDefer[i].getAttribute('data-src'));
            }
        }
    }
}

NavScrollAnchor.defaultProps = {
    href: null,
    text: null
}

export default NavScrollAnchor;
